import {ClockWrapper, MainWrapper, StartButton} from './styles.ts';
import {ClockItem} from "./ui/ClockItem.tsx";
import {useEffect, useState} from "react";
import {ColonItem} from "./ui/ColonItem.tsx";
import {useAppSelector} from "../../shared/lib/redux/useAppSelector.ts";
import {showClockSelector, timeOverSelector} from "../../features/Clock/store/selectors.ts";
import {useAppDispatch} from "../../shared/lib/redux/useAppDispatch.ts";
import {setShowBanner, setShowClock, setTimeOver} from "../../features/Clock/store/reducer.ts";

const BIRTHDAY = new Date("2026-01-27T00:40:00")

export const Clock = () => {
    const [clickCount, setClickCount] = useState(0)
    const [time, setTime] = useState(new Date())
    const [hours, setHours] = useState(0)
    const [minutes, setMinutes] = useState(0)
    const [seconds, setSeconds] = useState(0)

    const timeOver = useAppSelector(timeOverSelector)
    const showClock = useAppSelector(showClockSelector)

    const dispatch = useAppDispatch()

    useEffect(() => {
        if (clickCount === 3) {
            dispatch(setShowBanner(true))
            dispatch(setShowClock(false))
        }
    }, [clickCount])


    useEffect(() => {
        const timer = setInterval(() => setTime(new Date()), 1000)

        return () => {
            clearInterval(timer)
        }
    }, [])

    useEffect(() => {
        const timeDiff = BIRTHDAY.getTime() - time.getTime()

        if (timeDiff === 0 || timeDiff < 0) {
            setHours(0)
            setMinutes(0)
            setSeconds(0)
            dispatch(setTimeOver(true))
        } else {

            const dirtyHours = timeDiff / (60 * 60 * 1000)
            const hours = Math.trunc(dirtyHours)

            const dirtyMinutes = (dirtyHours - hours) * 60
            const minutes = Math.trunc(dirtyMinutes)

            const seconds = Math.trunc((dirtyMinutes - minutes) * 60)

            setHours(hours)
            setMinutes(minutes)
            setSeconds(seconds)
        }
    }, [time])

    const clickHandler = () => {
        setClickCount(clickCount + 1)
    }

    if (!showClock) {
        return null
    }

  return (
      <ClockWrapper>
          <MainWrapper $timeOver={timeOver} >
              {timeOver && <StartButton onClick={clickHandler} >GO!  GO!  GO!</StartButton>}
              <ClockItem digits={hours} />
              <ColonItem/>
              <ClockItem digits={minutes} />
              <ColonItem/>
              <ClockItem digits={seconds} />
          </MainWrapper>
      </ClockWrapper>
  )
};
